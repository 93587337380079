export function persistSitemapData(dataJson: Object) {

    if (typeof(dataJson) !== 'undefined' &&  dataJson != null) {
        const jsonString = JSON.stringify( dataJson);
        localStorage.setItem(SessionKeys.BreadcrumbSiteMapEntry, jsonString) ;
    }

}

export function retrieveSitemapData() {
    let dataJson = null;
    const sitemapJsonString = localStorage.getItem(SessionKeys.BreadcrumbSiteMapEntry);
    if (sitemapJsonString != null && sitemapJsonString !=='null') {
        dataJson = JSON.parse(sitemapJsonString);
    }
    return dataJson;
}